import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const CreateCasePrivateRoute = ({ component: Component, ...rest }) => {
    if (rest.location.search != "") {
        sessionStorage.setItem("PreviousPath", `${rest.location.pathname}${rest.location.search}`);
    } else {
        sessionStorage.setItem("PreviousPath", rest.location.pathname);
    }
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            localStorage.getItem('isvalid') ?
                <Component {...props} />
                : <Redirect to="/" />
        )} />
    );
};

export default CreateCasePrivateRoute;