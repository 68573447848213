import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import Loading from '../Loader/Loader';
import {
    getPortalLogo,
    checkMarketSourceGuid
} from "../../redux/actions/UserActions";
import CheckValidAuthorized from '../Error/AuthorizationMessage';

class ValidateMarketSource extends Component {

    constructor(props) {
        super(props)
        this.state = {
            success: false,
            message: '',
            isvalid: false,
            portallogo: localStorage.getItem("publicportallogo")
        }
    }
    componentWillMount() {
        localStorage.clear();
        this.props.getPortalLogo();
        const getGuiId = `?Id=${this.props.match.params.id}`;
        this.props.checkMarketSourceGuid(getGuiId)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.marketsourcedata !== this.props.marketsourcedata) {
            this.setState({
                success: nextProps.marketsourcedata.Success,
                message: nextProps.marketsourcedata.Message,
                isvalid: true
            }, () => {
                if (nextProps.marketsourcedata.Success) {
                    localStorage.setItem('isvalid', 'true');
                    this.props.history.push({
                        pathname: '/CreateClient',
                        marketSourceId: this.props.match.params.id,

                    })

                }

            });
        }
        this.setState({
            portallogo: localStorage.getItem('publicportallogo')
        })

    }
    render() {

        if (this.props.error && this.props.error) {
            return (<CheckValidAuthorized />)
        }
        return (

            <>
                {this.props.fetching && !this.props.error ? (

                    <div className="full-background">
                        <div className={"header-" + window.globalConfig.CustomerName + " container-fluid"}>
                            <div className="row align-items-center">
                                <div className="col-6 d-flex">
                                    <div className="logo">
                                        <Link to="/"><img src={this.state.portallogo} alt="Legal365" height="100%" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Loading />
                    </div>
                ) : (
                    <div>

                        <div className={"header-" + window.globalConfig.CustomerName + " container-fluid"}>
                            <div className="row align-items-center">
                                <div className="col-6 d-flex">
                                    <div className="logo">
                                        <Link to="/"><img src={this.state.portallogo} alt="Legal365" height="100%" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => ({

    portalLogo: state.user.portalLogo,
    marketsourcedata: state.user.marketsourcedata,
    fetching: state.user.fetching,
    error: state.error.isError,


});

const mapDispatchToProps = {
    getPortalLogo,
    checkMarketSourceGuid
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValidateMarketSource));


