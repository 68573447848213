
//Config file

// Enter your Api path 
export const apiPath = window.globalConfig.LegalAPIUrl;

// base URL
export const baseURL = window.globalConfig.LegalAPIBaseUrl;

// Custom headers
export const headers = {
    headers: {
        'Authorization': 'API_KEY ' + window.globalConfig.LegalAPIKey
    }
}

// Api endpoints with proper data
export const endPoints = {
    appLogoApi: { endPoint: "/getportallogo" },
    userApi: { endPoint: "/getuserinfo" },
    consentSave: { endPoint: "/updateconsent" },
    loginNews: { endPoint: "/login/getnews" },
    dashBoardNews: { endPoint: "/dashbaord/getnews" },
    news: { endPoint: "/getnews" },
    getArea: { endPoint: "/getareas" },
    getRelationships: { endPoint: "/getrelationships" },
    getBranches: { endPoint: "/getbranches" },
    getLawyer: { endPoint: "/getlawyers" },
    booking: { endPoint: "/booking" },
    getProfile: { endPoint: "/getprofileinfo" },
    saveProfile: { endPoint: "/updateprofile" },
    getAllCase: { endPoint: "/cases" },
    getCaseDetails: { endPoint: "/cases/legalcase" },
    uploadDocument: { endPoint: "/casedocument/upload" },
    allDocumentList: { endPoint: "/cases/getalldocuments" },
    createThread: { endPoint: "/case/threads" },
    allThread: { endPoint: "/case/allthreads" },
    getChat: { endPoint: "/case/messages" },
    createChat: { endPoint: "/case/messages" },
    updateChat: { endPoint: "/case/updateMessage" },
    getPendingSurvey: { endPoint: "/survey" },
    getSurvey: { endPoint: "/survey/details" },
    getPublicSurvey: { endPoint: "/publicsurvey/details" },
    postSurvey: { endPoint: "/survey/details" },
    getredirecturlforbankid: { endPoint: "/getredirecturlforbankid" },
    callbackforbankid: { endPoint: "/callbackforbankid" },
    callbackforsurveysigning: { endPoint: "/survey/callback" },
    checkvalidmarketsourceguid: { endPoint: "/checkvalidmarketsource" },
    createcase: { endPoint: "/createcase" }
};


//Vipps configuration
export const clientId = window.globalConfig.VippsClientId;
export const scope = "phoneNumber name email address";
export const responseType = "code";
export const redirectUri = window.globalConfig.VippRedirectUri;

export const vippsWellKnownOpenIdConfigurationUrl = window.globalConfig.VippsOpenIdConfigurationUrl;

// Link configuration 
export const backLink = window.globalConfig.loginbackUrl;

//Norway date time format
export const dateTimeFormat = { dateFormat: "DD.MM.YYYY, HH.mm", TimeFormat: "HH:mm", dateOnlyFormat: "DD.MM.YYYY" }

//Vipps configuration
export const bankdIdClientId = window.globalConfig.bankIdClientId;
export const bankIdClientSecret = window.globalConfig.bankIdClientSecret;
export const oidcBaseUrl = window.globalConfig.oidcBaseUrl;
export const bankIdRedirectUrl = window.globalConfig.bankIdRedirectUrl;