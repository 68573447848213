import React, { Component } from 'react';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from "react-redux";
import { createCase, getPortalLogo } from "../../redux/actions/UserActions";
import Loading from '../Loader/Loader';
import { Link } from 'react-router-dom';

import CheckValidAuthorized from '../Error/AuthorizationMessage';




class CreateCase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            description: "",
            claimNumber: "",
            counterPartyFirstName: "",
            counterPartySurname: "",
            dateOfBirth: "",
            counterPartyRef: "",
            documents: [],
            files: [],
            validtoUpload: true,
            descriptionError: false,
            claimNumberError: false,
            errorMessage: false,
            allData: props.location.state ? props.location.state.allFormsData : [],
            marketSourceId: props.location.marketSourceId,
            success: false,
            isauthorized: false,
            createCaseHeader: localStorage.getItem("createCaseHeader"),
            createCaseFormInfo: localStorage.getItem("createCaseFormInfo"),
            portallogo: localStorage.getItem("publicportallogo"),
        };

        this.handleDescription = this.handleDescription.bind(this);
        this.handleClaimNumber = this.handleClaimNumber.bind(this);
        this.handleCounterPartyFirstName = this.handleCounterPartyFirstName.bind(this);
        this.handleCounterPartySurname = this.handleCounterPartySurname.bind(this);
        this.handleDateOfBirth = this.handleDateOfBirth.bind(this);
        this.handleCoounterPartyRef = this.handleCoounterPartyRef.bind(this);
        this.handleCreateFormSubmit = this.handleCreateFormSubmit.bind(this);

    }

    componentDidMount() {
        if (this.state.allData === 0) {
            this.props.history.push(`/CreateClient`);
        }
        this.props.getPortalLogo();

    }
    componentWillReceiveProps() {
        this.setState({
            portallogo: localStorage.getItem('publicportallogo'),
            createClientHeader: localStorage.getItem("createClientHeader"),
            createClientFormInfo: localStorage.getItem("createClientFormInfo"),
        })

    }
    componentDidUpdate(prevProps) {
        if (this.props.createcasedata !== prevProps.createcasedata) {
            if (this.props.createcasedata.Success) {
                this.setState({ success: true });
                this.props.history.push({
                    pathname: '/CreateCaseSuccess',
                    isauthorized: true
                })

            }
        }

    }



    handleDescription = (e) => {
        this.setState({
            description: e.target.value
        });
        this.setState({
            descriptionError: false
        })
    };

    handleClaimNumber = (e) => {
        this.setState({
            claimNumber: e.target.value
        });
        this.setState({
            claimNumberError: false
        })
    };

    handleCounterPartyFirstName = (e) => {
        this.setState({
            counterPartyFirstName: e.target.value
        });
    };

    handleCounterPartySurname = (e) => {
        this.setState({
            counterPartySurname: e.target.value
        });
    };

    handleDateOfBirth = (e) => {
        this.setState({
            birthDate: e.target.value
        });
    };

    handleCoounterPartyRef = (e) => {
        this.setState({
            counterPartyRef: e.target.value
        });
    };

    handleCreateFormSubmit() {
        const { description, claimNumber, counterPartyFirstName, counterPartySurname, counterPartyRef, documents, allData, marketSourceId } = this.state;

        if (!description && !claimNumber) {
            this.setState({
                descriptionError: true,
                claimNumberError: true,
                errorMessage: true
            });
        } else if (!description) {
            this.setState({
                descriptionError: true,
                errorMessage: true
            });
        } else if (!claimNumber) {
            this.setState({
                claimNumberError: true,
                errorMessage: true
            });
        } else {
            const clients = allData.map(client => ({
                firstName: client.firstName,
                lastName: client.lastName,
                email: client.email,
                phoneNumber: client.number,
                birthDate: client.birthDate ? moment(client.birthDate).toISOString() : "",
                streetName: client.streetName,
                postalcode: client.postalcode,
                city: client.city
            }));

            const payload = {
                marketSourceId: marketSourceId,
                description: description,
                claimNumber: claimNumber,
                counterPartFirstName: counterPartyFirstName,
                counterPartLastName: counterPartySurname,
                counterPartRefNo: counterPartyRef,
                documents: documents,
                clients: clients,

            };



            this.props.createCase(payload)




            this.setState({
                errorMessage: false,
                descriptionError: false,
                claimNumberError: false,
            });
        }
    }

    onDrop = (files) => {

        files.forEach((file) => {
            const document = {};
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let base64string = reader.result;
                base64string = base64string.split(",").pop();
                document.fileName = file.name;
                document.base64String = base64string;
                this.setState(prevState => ({
                    documents: [...prevState.documents, document],
                    files: [...prevState.files, file]
                }));
            };
            reader.onerror = (error) => {
                console.log("Error: ", error);
            };
        });

        this.validateFiles(files);
    };

    validateFiles = (files) => {
        let totalFileSize = 0;
        files.forEach((file) => {
            let megabytes = (file.size / (1024 * 1024)).toFixed();
            totalFileSize = parseInt(totalFileSize) + parseInt(megabytes);
        });
        if (totalFileSize > 90) {
            this.setState({ validtoUpload: false });
        } else {
            this.setState({ validtoUpload: true });
        }
    };

    remove = (fileName) => {
        var filteredArray = this.state.files.filter((e) => e.name !== fileName);
        this.setState({
            files: filteredArray,
        });
        this.validateFiles(filteredArray);
    };

    render() {

        const files = this.state.files.map((file) => (
            <tr key={file.name}>


                <td >
                    {file.name} - {file.size} bytes
                </td>
                <td>
                    <span
                        style={{ marginLeft: "100px", marginTop: "12px", cursor: "pointer" }}
                        onClick={() => this.remove(file.name)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </td>

            </tr>
        ));
        if (this.props.fetching && !this.props.error) {
            return (<Loading />)
        }
        if (this.props.error && this.props.error) {
            return (<CheckValidAuthorized />)
        }

        return (

            <>

                <div className="full-background">
                    <div className={"header-" + window.globalConfig.CustomerName + " container-fluid"}>
                        <div className="row align-items-center">
                            <div className="col-6 d-flex">
                                <div className="logo">
                                    <Link to="/"><img src={this.state.portallogo} alt="Legal365" height="100%" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="col-12 col-lg-12 mb-4">
                            <div className='text-center'>
                                <h2 className='create-case-ttl'>{this.state.createCaseHeader}</h2>
                                <p className='mb-4'>{this.state.createCaseFormInfo}</p>
                            </div>
                            <div className="box-container progressform mb-4">
                                <Form>
                                    <Form.Row className="mb-3 creat-form-center">
                                        <Form.Label column sm={1}>
                                            Skadebeskrivelse
                                            <span style={{ color: "red", marginLeft: "0.5rem" }}>*</span>
                                        </Form.Label>
                                        <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                            <Form.Control
                                                as="textarea"
                                                style={{ minHeight: "100px" }}
                                                placeholder=""
                                                onChange={this.handleDescription}
                                                value={this.state.description}
                                                required
                                            />
                                            {this.state.descriptionError && (
                                                <div style={{ color: "red" }}>Feltet er påkrevet</div>
                                            )}
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="mb-3 creat-form-center">
                                        <Form.Label column sm={1}>
                                            Skadenummer
                                            <span style={{ color: "red", marginLeft: "0.5rem" }}>*</span>
                                        </Form.Label>
                                        <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                            <Form.Control
                                                style={{ minHeight: "0" }}
                                                type="text"
                                                placeholder=""
                                                onChange={this.handleClaimNumber}
                                                value={this.state.claimNumber}
                                                required
                                            />
                                            {this.state.claimNumberError && (
                                                <div style={{ color: "red" }}>Feltet er påkrevet</div>
                                            )}
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="mb-3 creat-form-center">
                                        <Form.Label column sm={1}>Fornavn motpart</Form.Label>
                                        <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                            <Form.Control
                                                style={{ minHeight: "0" }}
                                                type="text"
                                                onChange={this.handleCounterPartyFirstName}
                                                value={this.state.counterPartyFirstName}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="mb-3 creat-form-center">
                                        <Form.Label column sm={1}>
                                            Etternavn motpart
                                        </Form.Label>
                                        <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                            <Form.Control
                                                style={{ minHeight: "0" }}
                                                type="text"
                                                placeholder=""
                                                onChange={this.handleCounterPartySurname}
                                                value={this.state.counterPartySurname}
                                            />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mb-3 creat-form-center">
                                        <Form.Label column sm={1}>
                                            Motpartens ref
                                        </Form.Label>
                                        <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                            <Form.Control
                                                style={{ minHeight: "0" }}
                                                type="text"
                                                placeholder=""
                                                onChange={this.handleCoounterPartyRef}
                                                value={this.state.counterPartyRef}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="mb-3 creat-form-center">
                                        <Col className="col-5">
                                            <Dropzone multiple={true} isDragActive={true} onDrop={this.onDrop}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className='caseFileDrop'>
                                                        <div {...getRootProps()} style={{ border: '1px dashed #122e43', textAlign: 'center', width: "100%" }} className="fileDropd">
                                                            <input {...getInputProps()} />
                                                            <p>Last opp filer</p>
                                                        </div>
                                                        <aside style={{
                                                            maxHeight: '100px',
                                                            overflowY: 'scroll',
                                                            scrollbarWidth: 'none',
                                                            msOverflowStyle: 'none'
                                                        }}>
                                                            <table style={{ width: '100%', borderCollapse: 'collapse', }}>


                                                                <tbody>{files}</tbody>

                                                            </table>
                                                        </aside>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mb-3 text-uppercase pt-4 text-center">
                                        <Button
                                            className="generalbtn pl-3 pr-3 pt-2 pb-2 m-auto"
                                            type="button"
                                            onClick={this.handleCreateFormSubmit}
                                        >
                                            SEND INN
                                        </Button>
                                    </Form.Row>
                                </Form>
                            </div>
                        </div>
                    </>

                    <div>

                    </div>

                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    createcasedata: state.user.createcasedata,
    fetching: state.user.fetching,
    error: state.error.isError,

});

const mapDispatchToProps = {
    createCase,
    getPortalLogo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCase));
