import React, { Component } from 'react';
import "../../assets/css/Dashboard.css";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getPortalLogo,

} from "../../redux/actions/UserActions";



class CreateClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfForms: 1,
            currentFormIndex: 0,
            forms: Array.from({ length: 1 }, () => ({
                firstName: "",
                lastName: "",
                email: "",
                number: "",
                birthDate: "",
                streetName: "",
                postCode: "",
                city: "",
                marketSourceId: props.location.marketSourceId,
            })),
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            disable: false,
            success: false,
            message: '',
            isvalid: false,
            createClientHeader: localStorage.getItem("createClientHeader"),
            createClientFormInfo: localStorage.getItem("createClientFormInfo"),
            portallogo: localStorage.getItem("publicportallogo"),
        };

        this.handleNumberOfFormsChange = this.handleNumberOfFormsChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleCreateFormSubmit = this.handleCreateFormSubmit.bind(this);
    }

    componentWillMount() {

        this.props.getPortalLogo();

    }
    componentWillReceiveProps() {
        this.setState({
            portallogo: localStorage.getItem('publicportallogo'),
            createClientHeader: localStorage.getItem("createClientHeader"),
            createClientFormInfo: localStorage.getItem("createClientFormInfo"),
        })

    }

    handleNumberOfFormsChange(e) {
        const count = parseInt(e.target.value);
        this.setState((prevState) => {
            const newCurrentFormIndex = Math.min(prevState.currentFormIndex, count - 1);

            const newForms = Array.from({ length: count }, (_, i) => prevState.forms[i] || {
                firstName: "",
                lastName: "",
                email: "",
                number: "",
                birthDate: "",
                streetName: "",
                postCode: "",
                city: "",


            });

            return {
                numberOfForms: count,
                forms: newForms,
                currentFormIndex: newCurrentFormIndex,
            };
        });
    }

    handleFormChange(index, field) {
        return (e) => {
            const newForms = [...this.state.forms];
            newForms[index] = {
                ...newForms[index],
                [field]: e.target.value,
            };
            if (field === 'firstName' && e.target.value) {
                this.setState({ firstNameError: false });
            }
            if (field === 'lastName' && e.target.value) {
                this.setState({ lastNameError: false });
            }
            if (field === 'email') {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emailRegex.test(e.target.value)) {
                    this.setState({ emailError: false });
                }
            }
            this.setState({ forms: newForms });
        };
    }


    handleCreateFormSubmit() {
        const currentForm = this.state.forms[this.state.currentFormIndex];
        const firstNameError = !currentForm.firstName;
        const lastNameError = !currentForm.lastName;
        const emailError = currentForm.email && !this.validateEmail(currentForm.email);

        if (firstNameError || lastNameError || emailError) {
            this.setState({ firstNameError, lastNameError, emailError });
        } else {
            this.setState((prevState) => {
                if (prevState.currentFormIndex < prevState.numberOfForms - 1) {
                    return {
                        firstNameError: false,
                        lastNameError: false,
                        emailError: false,
                        currentFormIndex: prevState.currentFormIndex + 1,
                        disable: true
                    };
                } else {
                    const allFormData = this.state.forms.map(form => ({
                        firstName: form.firstName,
                        lastName: form.lastName,
                        email: form.email,
                        number: form.number,
                        birthDate: form.birthDate,
                        streetName: form.streetName,
                        postalcode: form.postCode,
                        city: form.city,



                    }));



                    this.props.history.push({
                        pathname: '/CreateCase',
                        state: { allFormsData: allFormData },
                        marketSourceId: this.props.location.marketSourceId,


                    })


                    return { firstNameError: false, lastNameError: false, emailError: false };
                }

            });
        }
    }

    validateEmail(email) {

        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    renderCurrentForm() {
        const form = this.state.forms[this.state.currentFormIndex];
        const index = this.state.currentFormIndex;

        return (
            <>

                <div className="box-container progressform mb-4">

                    <div className="form-section">
                        <Form.Row className="mb-3 creat-form-center">
                            <Form.Label column sm={1}>Fornavn klient <span style={{ color: "red", marginLeft: "0.5rem" }}>*</span></Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    style={{ minHeight: "0" }}
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleFormChange(index, 'firstName')}
                                    value={form.firstName}
                                    required
                                />
                                {this.state.firstNameError ? (
                                    <p style={{ color: "red" }}>Feltet er påkrevet</p>
                                ) : null}
                            </Col>
                        </Form.Row>

                        <Form.Row className="mb-3 creat-form-center">
                            <Form.Label column sm={1}>Etternavn klient <span style={{ color: "red", marginLeft: "0.5rem" }}>*</span></Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    style={{ minHeight: "0" }}
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleFormChange(index, 'lastName')}
                                    value={form.lastName}
                                    required
                                />
                                {this.state.lastNameError ? (
                                    <p style={{ color: "red" }}>Feltet er påkrevet</p>
                                ) : null}
                            </Col>
                        </Form.Row>

                        <Form.Row className="mb-3 creat-form-center">
                            <Form.Label column sm={1}>E-post</Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    style={{ minHeight: "0" }}
                                    type="text"
                                    onChange={this.handleFormChange(index, 'email')}
                                    value={form.email}
                                />
                                {this.state.emailError ? (
                                    <p style={{ color: "red" }}>Ugyldig epostadresse</p>
                                ) : null}
                            </Col>
                        </Form.Row>


                        <Form.Row className="mb-3 creat-form-center">
                            <Form.Label column sm={1}>Telefonnummer</Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    style={{ minHeight: "0" }}
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleFormChange(index, 'number')}
                                    value={form.number}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-3 creat-form-center">
                            <Form.Label column sm={1}>Fødselsdato</Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    style={{ minHeight: "0" }}
                                    type="date"
                                    placeholder=""
                                    onChange={this.handleFormChange(index, 'birthDate')}
                                    value={form.birthDate}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-3 creat-form-center">
                            <Form.Label column sm={1}>Gatenavn</Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    style={{ minHeight: "0" }}
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleFormChange(index, 'streetName')}
                                    value={form.streetName}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-3 creat-form-center">
                            <Form.Label column sm={1}>Postkode</Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    style={{ minHeight: "0" }}
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleFormChange(index, 'postCode')}
                                    value={form.postCode}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-3 creat-form-center">
                            <Form.Label column sm={1}>Sted</Form.Label>
                            <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <Form.Control
                                    style={{ minHeight: "0" }}
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleFormChange(index, 'city')}
                                    value={form.city}
                                />
                            </Col>

                        </Form.Row>
                    </div>
                </div >
            </>
        );
    }

    render() {

        return (
            <>

                <div className="full-background">
                    <div className={"header-" + window.globalConfig.CustomerName + " container-fluid"}>
                        <div className="row align-items-center">
                            <div className="col-6 d-flex">
                                <div className="logo">
                                    <Link to="/"><img src={this.state.portallogo} alt="Legal365" height="100%" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <>
                        <div className="col-12 col-lg-12 mb-4">
                            <div className='text-center'>
                                <h2 className='create-case-ttl'>{localStorage.getItem("createClientHeader")}</h2>
                                <p className='mb-4'>{this.state.createClientFormInfo}</p>
                            </div>
                            <div className="box-container progressform mb-4">
                                <Form>
                                    <Form.Row className="mb-3 creat-form-center">
                                        <Form.Label column sm={1}>Antall klienter</Form.Label>
                                        <Col className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                            <Form.Control
                                                as="select"
                                                onChange={this.handleNumberOfFormsChange}
                                                value={this.state.numberOfForms}
                                                disabled={this.state.disable}
                                            >
                                                {[1, 2, 3, 4, 5].map(num => (
                                                    <option key={num} value={num}>{num}</option>
                                                ))}
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    {this.renderCurrentForm()}
                                    <Form.Row className="mb-3 text-uppercase pt-4 text-center">
                                        <Button
                                            className="generalbtn pl-3 pr-3 pt-2 pb-2 m-auto"
                                            type="button"
                                            onClick={this.handleCreateFormSubmit}
                                        >
                                            Neste
                                        </Button>
                                    </Form.Row>
                                </Form>
                            </div>
                        </div>
                    </>



                </div>
            </>

        );
    }
}
const mapStateToProps = (state) => ({

    portalLogo: state.user.portalLogo,
    marketsourcedata: state.user.marketsourcedata,
    fetching: state.user.fetching,
    error: state.error.isError,


});

const mapDispatchToProps = {
    getPortalLogo,

};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateClient));





