import React, { Component } from 'react';
import {IDividerAsProps, TooltipHost } from "@fluentui/react";
import { Button } from 'react-bootstrap';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLandmark, faDownload,faUser } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router";
//Badge
import Badge from 'react-bootstrap/Badge'
//ToolTip
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// Language translate import
import { withTranslation } from 'react-i18next';
// check client Authentication
import { checkClientAuthentication, checkOnBoardRequiredForPages } from '../../utils/restriction';
// Connect react and redux
import { connect } from 'react-redux';
// Action call from redux
import { reset, getDocumentList } from '../../redux/actions/UserActions';
import Loading from '../Loader/Loader';
import { errorToast, successToast, infoToast, dismissInfo } from '../Notification/Notification';
import ErrorPage from '../Error/ErrorPage';
import moment from 'moment';
import { downloadDocument } from "../../services/RestAPIWrapper"
import { dateTimeFormat } from '../../config/ApiConfig';
class DocumentDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            documents: [],
            nextDocument: null,
            isLoadMore: true,
            fpath: "root",
            itemsWithHeading: []
        }
    }
    componentWillMount() {
        const check = checkOnBoardRequiredForPages(this.props);
        if (!check) {
            // check client Authentication
            const clinetAuthentication = checkClientAuthentication();
            if (!clinetAuthentication || !this.props.location.state) {
                this.props.history.push('/CaseList')
            } else {
                this.getDocuments(this.state.fpath);
            }
        }
    }

    componentWillReceiveProps(nextprops) {
        if (Object.keys(nextprops.documentlist).length > 0) {
            let itemsWithHeading = [];
            let sharePointUrl;
            const documentListData = nextprops.documentlist;
            if (documentListData.Success) {
                this.setState({
                    documents: documentListData.Result.DocumentList,
                    nextDocument: documentListData.Result.pageInfo,
                    isLoadMore: documentListData.Result.isMoreDocumentExist
                });
                sharePointUrl = documentListData.Result.sharePointUrl;
                var rootPath = sharePointUrl.split("sharepoint.com")[1];
                let folderPath = this.state.fpath == "root" ? rootPath : this.state.fpath;
                let breadcrumbItems = folderPath.split("/");
                let rootFolder = "Hovedmappe";
                let prifixKey = breadcrumbItems.slice(0, 5).join("/");
                if (breadcrumbItems.length > 5) {
                    breadcrumbItems = breadcrumbItems.slice(5, breadcrumbItems.length);
                    breadcrumbItems.splice(0, 0, rootFolder);
                } else {
                    breadcrumbItems = [];
                    breadcrumbItems.push(rootFolder);
                }
                let keyValue = "";
                breadcrumbItems.forEach((element) => {
                    if (element == rootFolder) {
                        keyValue += prifixKey;
                    } else {
                        keyValue += "/" + element;
                    }
                    itemsWithHeading.push({
                        text: element,
                        key: keyValue,
                        onClick: this.onBreadcrumbItemClicked,
                    });
                })
                this.setState({
                    itemsWithHeading: itemsWithHeading
                })
            }
            else {
                errorToast(documentListData.Message)
            }
        }
    }
    getDocuments = (folderpath) => {
        this.setState({
            fpath: folderpath
        });

        const folderPath = folderpath ? folderpath : "root";
        const caseid = this.props.location.state.caseId;
        const clientId = JSON.parse(localStorage.getItem("UserInfo")).userId;
        const documentDetailsParams = `?caseId=${caseid}&pageInfo=${this.state.nextDocument}&clientId=${clientId}&folderPath=${encodeURIComponent(folderPath)}`;
        this.props.getDocumentList(documentDetailsParams);
    }
    onBreadcrumbItemClicked = (ev, item) => {
        if (item) {
            this.getDocuments(item.key);
        }
        else {
            this.getDocuments(this.state.fpath);
        }
    }
    _getCustomDivider(dividerProps: IDividerAsProps): JSX.Element {
        const tooltipText = dividerProps.item ? dividerProps.item.text : "";
        return (
            <TooltipHost
                content={`Show ${tooltipText} contents`}
                calloutProps={{ gapSpace: 0 }}
            >
                <span aria-hidden="true" style={{ cursor: "pointer", padding: 5 }}>
                    /
                </span>
            </TooltipHost>
        );
    }
    handleDownload = (downloadURL, name) => {
        infoToast(this.props.t('caseDetails.downloadInfo'))
        downloadDocument(downloadURL, 'blob', response => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();
                dismissInfo()
                setTimeout(() => {
                    successToast(this.props.t('caseDetails.downloadSuccess'))
                }, 500);
            } else {
                dismissInfo()
                errorToast(this.props.t('caseDetails.downloadError'))
            }
        }, err => {
            dismissInfo()
            errorToast(this.props.t('caseDetails.downloadError'))
            console.error(err);
        });
    }

    handleLoadMore = (fpath) => {
        const folderPath = fpath ? fpath : "root";
        const caseid = this.props.location.state.caseId;
        const clientId = JSON.parse(localStorage.getItem("UserInfo")).userId;
        const documentDetailsParams = `?caseId=${caseid}&pageInfo=${this.state.nextDocument}&clientId=${clientId}&folderPath=${encodeURIComponent(folderPath)}`;
        this.props.getDocumentList(documentDetailsParams);
    }
    render() {
        return (
            <div className="Dashboard">
                {this.props.fetching && !this.props.error && <Loading />}
                <Header></Header>
                <div className="main-container">
                    <Sidebar />
                    {this.props.error ? <ErrorPage /> :
                        <div className="content-area-details alignedBox">
                            <div className="col-md-12">
                                <h1 className="ml-3 mb-3 case-title">{this.props.t('caseDetails.documents')}</h1>
                                <div className="item">
                                    {this.state.documents && this.state.documents.length > 0 ?
                                        this.state.documents.map((key, i) =>
                                        (
                                            <div key={i}>
                                                <ul key={i} className={key.IsLatestDocument ? "documents_list newDocument" : "documents_list"}>
                                                    <li>
                                                        <div>
                                                            <OverlayTrigger
                                                                key={"top"}
                                                                placement={"top"}
                                                                overlay={
                                                                    <Tooltip id={i}>
                                                                        {`${key.Author} ${key.relativeUrl.split('/').slice(4).join('/')}`}.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <FontAwesomeIcon className="documentCategory color1" icon={key.Author === "Lawyer" ? faLandmark : faUser} />
                                                            </OverlayTrigger>
                                                            <span>{key.Name} {key.IsLatestDocument ? <Badge className="ml-2 newbage">New</Badge> : ""}</span>
                                                            <span>{moment(key.CreatedDate).format(dateTimeFormat.dateFormat)}</span>
                                                            <a className="documentDownload"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => { this.handleDownload(key.DocumentUrl, key.Name) }}
                                                            >
                                                                <OverlayTrigger
                                                                    key={"top"}
                                                                    placement={"top"}
                                                                    overlay={
                                                                        <Tooltip id={`d-${i}`}>
                                                                            {this.props.t('caseDetails.downloadTooltip')}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon className="color1" icon={faDownload} />
                                                                </OverlayTrigger>
                                                            </a>
                                                        </div>

                                                    </li>
                                                </ul>
                                            </div>
                                        )

                                        ) :
                                        <ul className="documents_list">
                                            <li>
                                                <span>{this.props.t('caseDetails.noDocument')}</span>
                                            </li>
                                        </ul>}
                                </div>
                            </div>
                            {this.state.documents && this.state.documents.length > 0 || !this.state.isLoadMore ?
                                <div className="col-12 d-flex align-items-center justify-content-center">
                                    <Button className="btn btn-primary generalbtn pl-5 pr-5" onClick={() => { this.handleLoadMore(this.state.fpath) }}>
                                        {this.props.t('caseDetails.documentLoadMoreButton')}
                                    </Button>
                                </div> : null
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    documentlist: state.user.documentList,
    fetching: state.user.fetching,
    error: state.error.isError
})

const mapDispatchToProps = {
    getDocumentList,
    reset
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(DocumentDetails)));