import React, { Component } from 'react'
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getPortalLogo,

} from "../../redux/actions/UserActions";

class CreateCaseSuccess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isauthorized: false,
            isauth: props.location.isauthorized,
            confirmationMessage: localStorage.getItem("confirmationMessage"),
            portallogo: localStorage.getItem("publicportallogo"),
        }
    }

    componentWillMount() {

        this.props.getPortalLogo();

    }
    componentWillReceiveProps() {
        this.setState({
            portallogo: localStorage.getItem('publicportallogo'),
            confirmationMessage: localStorage.getItem("confirmationMessage"),
        })

    }
    componentWillUnmount() {
        this.props.history.push('/')
    }

    render() {
        const { isauthorized, isauth } = this.state;


        if (!isauthorized && !isauth) {
            this.props.history.push('/CreateClient');
        }
        return (
            <>


                <div className="full-background pt-4">
                    <div className={"header-" + window.globalConfig.CustomerName + " container-fluid"}>
                        <div className="row align-items-center">
                            <div className="col-6 d-flex">
                                <div className="logo">
                                    <Link to="/"><img src={this.state.portallogo} alt="Legal365" height="100%" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='p-3'>

                        <div className='d-flex justify-content-center mt-5'>
                            <h2 style={{ width: '30rem' }} >{this.state.confirmationMessage}</h2>

                        </div>

                    </div>

                </div>

            </>
        )
    }
}
const mapStateToProps = (state) => ({

    portalLogo: state.user.portalLogo,
    marketsourcedata: state.user.marketsourcedata,
    fetching: state.user.fetching,
    error: state.error.isError,


});

const mapDispatchToProps = {
    getPortalLogo,

};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCaseSuccess)); 