import { get, post, getVippsAuthInfo } from "./../../services/RestAPIWrapper";
import {
    PORTALLOGO_REQUEST,
    PORTALLOGO_SUCCESS,
    PORTALLOGO_FAIL,
    VIPPSAUTHURL_REQUEST,
    VIPPSAUTHURL_SUCCESS,
    VIPPSAUTHURL_FAIL,
    USERINFO_REQUEST,
    USERINFO_SUCCESS,
    USERINFO_FAIL,
    USER_CONSENTSAVE_REQUEST,
    USER_CONSENTSAVE_SUCCESS,
    USER_CONSENTSAVE_FAIL,
    AREA_REQUEST,
    AREA_SUCCESS,
    AREA_FAIL,
    RELATIONSHIP_REQUEST,
    RELATIONSHIP_SUCCESS,
    RELATIONSHIP_FAIL,
    BRANCHES_REQUEST,
    BRANCHES_SUCCESS,
    BRANCHES_FAIL,
    BOOKING_FAIL,
    BOOKING_REQUEST,
    BOOKING_SUCCESS,
    LAWYER_REQUEST,
    LAWYER_SUCCESS,
    LAWYER_FAIL,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_FAIL,
    PROFILE_SAVE_REQUEST,
    PROFILE_SAVE_SUCCESS,
    PROFILE_SAVE_FAIL,
    ALL_CASE_REQUEST,
    ALL_CASE_SUCCESS,
    ALL_CASE_FAIL,
    CASE_DETAIL_REQUEST,
    CASE_DETAIL_SUCCESS,
    CASE_DETAIL_FAIL,
    DOCUMENT_LIST_REQUEST,
    DOCUMENT_LIST_SUCCESS,
    DOCUMENT_LIST_FAIL,
    CREATE_THREAD_REQUEST,
    CREATE_THREAD_SUCCESS,
    CREATE_THREAD_FAIL,
    THREAD_LIST_REQUEST,
    THREAD_LIST_SUCCESS,
    THREAD_LIST_FAIL,
    PENDING_SURVEY_REQUEST,
    PENDING_SURVEY_SUCCESS,
    PENDING_SURVEY_FAIL,
    GET_PUBLICSURVEY_REQUEST,
    GET_PUBLICSURVEY_SUCCESS,
    GET_PUBLICSURVEY_FAIL,
    GET_SURVEY_REQUEST,
    GET_SURVEY_SUCCESS,
    GET_SURVEY_FAIL,
    POST_SURVEY_REQUEST,
    POST_SURVEY_SUCCESS,
    POST_SURVEY_FAIL,
    RESET,
    ISERRORSERVER,
    BANKIDURL_REQUEST,
    BANKIDURL_SUCCESS,
    BANKIDURL_FAIL,
    BANKIDUSERINFO_FAIL,
    BANKIDUSERINFO_SUCCESS,
    BANKIDUSERINFO_REQUEST,
    SURVEYCALLBACK_REQUEST,
    SURVEYCALLBACK_SUCCESS,
    SURVEYCALLBACK_FAIL,
    CREATE_CASE_REQUEST,
    CREATE_CASE_SUCCESS,
    CREATE_CASE_FAIL,
    CHECK_GUID_REQUEST,
    CHECK_GUID_SUCCESS,
    CHECK_GUID_FAIL,
} from '../constants/App';
import { endPoints, vippsWellKnownOpenIdConfigurationUrl } from '../../config/ApiConfig';


function callbackRequest() {
    return {
        type: SURVEYCALLBACK_REQUEST
    }
}

function callbackData(json) {
    return {
        type: SURVEYCALLBACK_SUCCESS,
        data: json
    }
}

export function callback(authorizationResponse) {
    return dispatch => {
        dispatch(callbackRequest());
        return post(endPoints.callbackforsurveysigning.endPoint, authorizationResponse, json => {
            if (json.Success === 'false') {
                return dispatch({ type: SURVEYCALLBACK_FAIL, data: json });
            } else {
                return (dispatch(callbackData(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        });
    };
}

//Action get APP logo from Dynamics 
function portalAppLogoRequest() {
    return {
        type: PORTALLOGO_REQUEST
    }
}

function portalAppLogoReceive(json) {
    return {
        type: PORTALLOGO_SUCCESS,
        data: json
    }
}

export function getPortalLogo() {

    return dispatch => {
        dispatch(portalAppLogoRequest());

        return get(endPoints.appLogoApi.endPoint, '', json => {

            if (json.Success == 'false') {
                return dispatch({ type: PORTALLOGO_FAIL, data: json });
            } else {
                localStorage.setItem("publicportallogo", `${json.Result.ImageBody}`);
                localStorage.setItem("sideBar", `${json.Result.SideBar}`)
                localStorage.setItem("createClientHeader", `${json.Result.portalConfig.createClientHeader}`)
                localStorage.setItem("createClientFormInfo", `${json.Result.portalConfig.createClientFormInfo}`)
                localStorage.setItem("createCaseHeader", `${json.Result.portalConfig.createCaseHeader}`)
                localStorage.setItem("createCaseFormInfo", `${json.Result.portalConfig.createCaseFormInfo}`)
                localStorage.setItem("confirmationMessage", `${json.Result.portalConfig.confirmationMessage}`)
                return (dispatch(portalAppLogoReceive(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        })
    };
}


//Login action get Vipps auth Url 
function vippsAuthurlRequest() {
    return {
        type: VIPPSAUTHURL_REQUEST
    }
}

function vippsAuthUrlReceiveData(json) {
    return {
        type: VIPPSAUTHURL_SUCCESS,
        data: json
    }
}

export function getVippsAuthUrl() {

    return dispatch => {
        dispatch(vippsAuthurlRequest());

        return getVippsAuthInfo(vippsWellKnownOpenIdConfigurationUrl, json => {
            if (json.Success == 'false') {
                return dispatch({ type: VIPPSAUTHURL_FAIL, data: json });
            } else {
                return (dispatch(vippsAuthUrlReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

//Login action get bank id url
function bankIdurlRequest() {
    return {
        type: BANKIDURL_REQUEST
    }
}

function bankIdurlReceiveData(json) {
    return {
        type: BANKIDURL_SUCCESS,
        data: json
    }
}

export function getbankIdUrl(requestParams) {

    return dispatch => {
        dispatch(bankIdurlRequest());

        return get(endPoints.getredirecturlforbankid.endPoint, requestParams, json => {
            if (json.Success == 'false') {
                return dispatch({ type: BANKIDURL_FAIL, data: json });
            } else {
                return (dispatch(bankIdurlReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Login actions
function request() {
    return {
        type: USERINFO_REQUEST
    }
}

function receiveData(json) {
    return {
        type: USERINFO_SUCCESS,
        data: json
    }
}

export function userInfo(userInfoBody) {
    return dispatch => {
        dispatch(request());

        return post(endPoints.userApi.endPoint, userInfoBody, json => {
            if (json.Success == 'false') {
                return dispatch({ type: USERINFO_FAIL, data: json });
            } else {

                if (json.Success) {
                    const userInfo = {
                        email: json.Result.email,
                        name: json.Result.name,
                        phone_number: json.Result.phone_number,
                        isOnboardingRequired: json.Result.isOnboardingRequired,
                        defaultVippsConcent: json.Result.defaultVippsConcent,
                        isClientAuthenticated: json.Result.isClientAuthenticated,
                        street1: json.Result.address.street_address,
                        city: json.Result.address.region,
                        country: json.Result.address.country,
                        zipcode: json.Result.address.postal_code,
                        dynamicsEntity: json.Result.dynamicsEntity,
                        userId: json.Result.userId
                    }
                    localStorage.setItem("UserInfo", JSON.stringify(userInfo));
                    localStorage.setItem("UserImage", json.Result.image);
                    localStorage.setItem("surveycount", json.Result.surveyCount);
                    localStorage.setItem("dynamicsEntity", json.Result.dynamicsEntity);
                }
                return (dispatch(receiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Login actions
function bankIdrequest() {
    return {
        type: BANKIDUSERINFO_REQUEST
    }
}

function bankdIdreceiveData(json) {
    return {
        type: BANKIDUSERINFO_SUCCESS,
        data: json
    }
}

export function bankdIdUserInfo(userInfoBody) {
    return dispatch => {
        dispatch(bankIdrequest());

        return post(endPoints.callbackforbankid.endPoint, userInfoBody, json => {
            if (json.Success == 'false') {
                return dispatch({ type: BANKIDUSERINFO_FAIL, data: json });
            } else {

                if (json.Success) {
                    const userInfo = {
                        email: json.Result.email,
                        name: json.Result.name,
                        phone_number: json.Result.phone_number,
                        defaultVippsConcent: json.Result.defaultVippsConcent,
                        isOnboardingRequired: json.Result.isOnboardingRequired,
                        isClientAuthenticated: json.Result.isClientAuthenticated,
                        street1: json.Result.address != null ? json.Result.address.street_address : "",
                        country: json.Result.address != null ? json.Result.address.country : "",
                        zipcode: json.Result.address != null ? json.Result.address.postal_code : "",
                        dynamicsEntity: json.Result.dynamicsEntity,
                        userId: json.Result.userId,
                    }
                    localStorage.setItem("UserInfo", JSON.stringify(userInfo));
                    localStorage.setItem("UserImage", json.Result.image);
                    localStorage.setItem("surveycount", json.Result.surveyCount);
                    localStorage.setItem("dynamicsEntity", json.Result.dynamicsEntity);
                }
                return (dispatch(bankdIdreceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// user Consent
function userConsentSaveRequest() {
    return {
        type: USER_CONSENTSAVE_REQUEST
    }
}

function userConsentSaveReceiveData(json) {
    return {
        type: USER_CONSENTSAVE_SUCCESS,
        data: json
    }
}

export function userConsentSave(consentSaveBody) {

    return dispatch => {
        dispatch(userConsentSaveRequest());

        return post(endPoints.consentSave.endPoint, consentSaveBody, json => {
            if (json.Success == 'false') {
                return dispatch({ type: USER_CONSENTSAVE_FAIL, data: json });
            } else {
                return (dispatch(userConsentSaveReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}


// Get Area
function areaRequest() {
    return {
        type: AREA_REQUEST
    }
}

function areaReceiveData(json) {
    return {
        type: AREA_SUCCESS,
        data: json
    }
}

export function getArea() {

    return dispatch => {
        dispatch(areaRequest());

        return get(endPoints.getArea.endPoint, '', json => {
            if (json.Success == 'false') {
                return dispatch({ type: AREA_FAIL, data: json });
            } else {
                return (dispatch(areaReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Get RelationShips
function relationShipsRequest() {
    return {
        type: RELATIONSHIP_REQUEST
    }
}

function relationShipsReceiveData(json) {
    return {
        type: RELATIONSHIP_SUCCESS,
        data: json
    }
}

export function getrelationShips() {

    return dispatch => {
        dispatch(relationShipsRequest());

        return get(endPoints.getRelationships.endPoint, '', json => {
            if (json.Success == 'false') {
                return dispatch({ type: RELATIONSHIP_FAIL, data: json });
            } else {
                return (dispatch(relationShipsReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Get Branches
function branchesRequest() {
    return {
        type: BRANCHES_REQUEST
    }
}

function branchesReceiveData(json) {
    return {
        type: BRANCHES_SUCCESS,
        data: json
    }
}

export function getBranches() {

    return dispatch => {
        dispatch(branchesRequest());

        return get(endPoints.getBranches.endPoint, '', json => {
            if (json.Success == 'false') {
                return dispatch({ type: BRANCHES_FAIL, data: json });
            } else {
                return (dispatch(branchesReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// get Lawyer
function lawyerRequest() {
    return {
        type: LAWYER_REQUEST
    }
}

function lawyerReceiveData(json) {
    return {
        type: LAWYER_SUCCESS,
        data: json
    }
}

export function getLawyer(lawyerParams) {

    return dispatch => {
        dispatch(lawyerRequest());

        return get(endPoints.getLawyer.endPoint, lawyerParams, json => {
            if (json.Success == 'false') {
                return dispatch({ type: LAWYER_FAIL, data: json });
            } else {
                return (dispatch(lawyerReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Save Booking actions
function bookingRequest() {
    return {
        type: BOOKING_REQUEST
    }
}

function bookingReceiveData(json) {
    return {
        type: BOOKING_SUCCESS,
        data: json
    }
}

export function bookingSave(bookingBody) {

    return dispatch => {
        dispatch(bookingRequest());

        return post(endPoints.booking.endPoint, bookingBody, json => {
            if (json.Success == 'false') {
                return dispatch({ type: BOOKING_FAIL, data: json });
            } else {
                return (dispatch(bookingReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Get Profile
function profileRequest() {
    return {
        type: PROFILE_REQUEST
    }
}

function profileReceiveData(json) {
    return {
        type: PROFILE_SUCCESS,
        data: json
    }
}

export function profile(profileParam) {

    return dispatch => {
        dispatch(profileRequest());

        return get(endPoints.getProfile.endPoint, profileParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: PROFILE_FAIL, data: json });
            } else {
                return (dispatch(profileReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Save Profile
function saveProfileRequest() {
    return {
        type: PROFILE_SAVE_REQUEST
    }
}

function saveProfileReceiveData(json) {
    return {
        type: PROFILE_SAVE_SUCCESS,
        data: json
    }
}

export function profileSave(profileBody) {

    return dispatch => {
        dispatch(saveProfileRequest());

        return post(endPoints.saveProfile.endPoint, profileBody, json => {
            if (json.Success == 'false') {
                return dispatch({ type: PROFILE_SAVE_FAIL, data: json });
            } else {
                return (dispatch(saveProfileReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}


// Get All Case
function allCaseRequest() {
    return {
        type: ALL_CASE_REQUEST
    }
}

function allCaseReceiveData(json) {
    return {
        type: ALL_CASE_SUCCESS,
        data: json
    }
}

export function getAllCase(caseParam) {

    return dispatch => {
        dispatch(allCaseRequest());

        return get(endPoints.getAllCase.endPoint, caseParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: ALL_CASE_FAIL, data: json });
            } else {
                return (dispatch(allCaseReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}


// Get case Details
function caseDetailRequest() {
    return {
        type: CASE_DETAIL_REQUEST
    }
}

function caseDetailReceiveData(json) {
    return {
        type: CASE_DETAIL_SUCCESS,
        data: json
    }
}

export function getCaseDetail(detailParam) {

    return dispatch => {
        dispatch(caseDetailRequest());

        return get(endPoints.getCaseDetails.endPoint, detailParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: CASE_DETAIL_FAIL, data: json });
            } else {
                return (dispatch(caseDetailReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Get Document List
function documentListRequest() {
    return {
        type: DOCUMENT_LIST_REQUEST
    }
}

function documentListReceiveData(json) {
    return {
        type: DOCUMENT_LIST_SUCCESS,
        data: json
    }
}

export function getDocumentList(listParam) {

    return dispatch => {
        dispatch(documentListRequest());

        return get(endPoints.allDocumentList.endPoint, listParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: DOCUMENT_LIST_FAIL, data: json });
            } else {
                return (dispatch(documentListReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Create Thread
function createThreadRequest() {
    return {
        type: CREATE_THREAD_REQUEST
    }
}

function createThreadReceiveData(json) {
    return {
        type: CREATE_THREAD_SUCCESS,
        data: json
    }
}

export function createThread(threadParams) {

    return dispatch => {
        dispatch(createThreadRequest());

        return post(endPoints.createThread.endPoint, threadParams, json => {
            if (json.Success == 'false') {
                return dispatch({ type: CREATE_THREAD_FAIL, data: json });
            } else {
                return (dispatch(createThreadReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Get Threads List
function threadListRequest() {
    return {
        type: THREAD_LIST_REQUEST
    }
}

function threadListReceiveData(json) {
    return {
        type: THREAD_LIST_SUCCESS,
        data: json
    }
}

export function getThreadList(listParam) {

    return dispatch => {
        dispatch(threadListRequest());

        return get(endPoints.allThread.endPoint, listParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: THREAD_LIST_FAIL, data: json });
            } else {
                return (dispatch(threadListReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}


// Get Threads List
function pendingSurveyListRequest() {
    return {
        type: PENDING_SURVEY_REQUEST
    }
}

function pendingSurveyListReceiveData(json) {
    return {
        type: PENDING_SURVEY_SUCCESS,
        data: json
    }
}

export function getPendingSurveyList(SurveyParam) {

    return dispatch => {
        dispatch(pendingSurveyListRequest());

        return get(endPoints.getPendingSurvey.endPoint, SurveyParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: PENDING_SURVEY_FAIL, data: json });
            } else {
                localStorage.setItem("surveycount", json.Result.surveyCount);
                return (dispatch(pendingSurveyListReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}


// Get Survey
function surveyListRequest() {
    return {
        type: GET_SURVEY_REQUEST
    }
}

function surveyListReceiveData(json) {
    return {
        type: GET_SURVEY_SUCCESS,
        data: json
    }
}

export function getSurvey(SurveyParam) {

    return dispatch => {
        dispatch(surveyListRequest());

        return get(endPoints.getSurvey.endPoint, SurveyParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: GET_SURVEY_FAIL, data: json });
            } else {

                return (dispatch(surveyListReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Post Survey
function postsurveyListRequest() {
    return {
        type: POST_SURVEY_REQUEST
    }
}

function postsurveyListReceiveData(json) {
    return {
        type: POST_SURVEY_SUCCESS,
        data: json
    }
}

export function postSurvey(SurveyParam) {

    return dispatch => {
        dispatch(postsurveyListRequest());

        return post(endPoints.postSurvey.endPoint, SurveyParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: POST_SURVEY_FAIL, data: json });
            } else {
                return (dispatch(postsurveyListReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Get Public Survey
function publicSurveyListRequest() {
    return {
        type: GET_PUBLICSURVEY_REQUEST
    }
}

function publicSurveyListReceiveData(json) {
    return {
        type: GET_PUBLICSURVEY_SUCCESS,
        data: json
    }
}

export function getPublicSurvey(SurveyParam) {

    return dispatch => {
        dispatch(publicSurveyListRequest());

        return get(endPoints.getPublicSurvey.endPoint, SurveyParam, json => {
            if (json.Success == 'false') {
                return dispatch({ type: GET_PUBLICSURVEY_FAIL, data: json });
            } else {
                localStorage.setItem("publicsurveyuser", `${json.Result.invitationName}`);
                return (dispatch(publicSurveyListReceiveData(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Check Valid MArketSource Guid

function checkGuidRequest() {
    return {
        type: CHECK_GUID_REQUEST
    }
}

function checkGuidSuccess(json) {
    return {
        type: CHECK_GUID_SUCCESS,
        data: json
    }
}
export function checkMarketSourceGuid(GuiId) {

    return dispatch => {
        dispatch(checkGuidRequest());

        return get(endPoints.checkvalidmarketsourceguid.endPoint, GuiId, json => {

            if (json.Success == 'false') {
                return dispatch({ type: CHECK_GUID_FAIL, data: json });
            } else {

                return (dispatch(checkGuidSuccess(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// create case
function createCaseRequest() {
    return {
        type: CREATE_CASE_REQUEST
    }
}

function createCaseSuccess(json) {
    return {
        type: CREATE_CASE_SUCCESS,
        data: json
    }
}

export function createCase(SurveyParam) {

    return dispatch => {
        dispatch(createCaseRequest());

        return post(endPoints.createcase.endPoint, SurveyParam, json => {

            if (json.Success == 'false') {
                return dispatch({ type: CREATE_CASE_FAIL, data: json });
            } else {
                return (dispatch(createCaseSuccess(json)));
            }
        }, err => {
            console.error(err);
            return dispatch(errorServer(err));
        });
    };
}

// Reset state 
function resetRequest() {
    return {
        type: RESET
    }
}
export function reset() {

    return dispatch => {
        dispatch(resetRequest());
    };
}



// Error 
export function errorServer(error) {
    return {
        type: ISERRORSERVER,
        data: error
    }
}