import React, { Component } from 'react';
// Language translate import
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {
    getPortalLogo,

} from "../../redux/actions/UserActions";


class AuthorizationMessage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            portallogo: localStorage.getItem("publicportallogo"),
        }
    }
    componentWillMount() {

        this.props.getPortalLogo();

    }
    componentWillReceiveProps() {
        this.setState({
            portallogo: localStorage.getItem('publicportallogo'),

        })

    }

    render() {
        return (
            <>
                <div style={{ background: "#eceff0", height: '100vh' }} >

                    <div className="full-background pt-4">
                        <div className={"header-" + window.globalConfig.CustomerName + " container-fluid"}>
                            <div className="row align-items-center">
                                <div className="col-6 d-flex">
                                    <div className="logo">
                                        <Link to="/"><img src={this.state.portallogo} alt="Legal365" height="100%" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: '10px', marginTop: '5%', textAlign: "center", fontSize: '25px', color: "Red" }}>
                            <p> {this.props.t('caseDetails.invalidcaseuser')} </p>
                        </div>
                    </div>


                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => ({

    portalLogo: state.user.portalLogo,
    marketsourcedata: state.user.marketsourcedata,
    fetching: state.user.fetching,
    error: state.error.isError,


});

const mapDispatchToProps = {
    getPortalLogo,

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AuthorizationMessage)))